import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<strong parentName="p">{`Cornerstone`}</strong>{` is the base state of reality as defined and governed by `}<a parentName="p" {...{
        "href": "/The%20Luminary",
        "title": "The Luminary"
      }}>{`The Luminary`}</a>{`. Any `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Convictions`}</a>{` manifested by `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{` may temporarily `}<em parentName="p">{`lift`}</em>{` reality from the Cornerstone. Once the Conviction is determined to be a `}<em parentName="p">{`falsehood`}</em>{` by the Luminary, reality will snap back to the Cornerstone. Depending on the magnitude of the `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Conviction`}</a>{` and the manner with which it was falsified, `}<a parentName="p" {...{
        "href": "/Backlash",
        "title": "Backlash"
      }}>{`Backlash`}</a>{` may occur.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      